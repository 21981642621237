<template>
  <div class="baseline_started_table" style="flex: 1; overflow: hidden">
    <el-table
      :data="nodes_table"
      :header-cell-style="{
        color: '#383838',
        fontWeight: 'blod',
        fontSize: '14px',
        backgroundColor: 'transparent',
      }"
      :cell-style="first_column_style"
      class="hide_body"
      style="width: 100%"
      @filter-change="filterHandler"
    >
      <!-- 索引 -->
      <el-table-column min-width="40" label="No."> </el-table-column>
      <!-- ID -->
      <el-table-column label="ID" min-width="80" :render-header="renderHeader">
      </el-table-column>
      <!-- 文件类型 -->
      <el-table-column
        :label="$t('baseline.nodeTable.type')"
        :filters="typeFiltersList"
        column-key="filterFileType"
      >
      </el-table-column>
      <!-- 节点名 -->
      <el-table-column
        prop="topic"
        :label="$t('baseline.nodeTable.name')"
        min-width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <!-- 状态 -->
      <el-table-column
        prop="status"
        :label="$t('baseline.nodeTable.status')"
        min-width="80"
      >
      </el-table-column>
      <!-- 节点类型 -->
      <!-- <el-table-column
        label="节点类型"
        :filters="node_filters"
        :filter-multiple="false"
        min-width="120">
      </el-table-column> -->
      <!-- 操作 -->
      <el-table-column
        :label="$t('baseline.nodeTable.operation')"
        min-width="80"
        :filters="filters"
        column-key="filterTag"
      >
      </el-table-column>
      <!-- 操作人 -->
      <el-table-column
        prop="baselineNodeCreatedBy"
        :label="$t('baseline.nodeTable.operator')"
        min-width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <!-- 操作时间 -->
      <el-table-column
        prop="baselineNodeCreatedAt"
        :label="$t('baseline.nodeTable.operationTime')"
        min-width="150"
        :formatter="formatCreateTime"
      >
      </el-table-column>

      <el-table-column label="" width="40" align="right"> </el-table-column>
      <el-table-column v-if="is_edit" label="" width="40" align="right">
      </el-table-column>
    </el-table>
    <div class="scroll_body">
      <!-- 添加节点下拉框 -->
     <baseline-addnode
        style="position: fixed; right: 20px;z-index:999"
        v-if="is_edit"
        :appen_to_body="true"
        @reload="reload"
    ></baseline-addnode>

      <collapse class="table_collapse">

        <template slot="title">
            {{ $t('baseline.nodeTable.block1') }}
        </template> 

        <template slot="pagination">
          <el-pagination
              :page-size="pageSize"
              :pager-count="pageCount"
              :current-page.sync="filter_nodes_current"
              class="header-pagination"
              :background="false"
              small
              layout="prev, pager, next"
              :total="filter_nodes_table.length"
              >
            </el-pagination>
        </template>
        <div class="baseline-nodes">
          <el-table
            v-loading="loading"
            :data="current_filter_nodes_table"
            :show-header="false"
            :header-cell-style="{
              color: 'rgba(84, 85, 86, 1)',
              fontWeight: 'blod',
              fontSize: '14px',
              backgroundColor: 'transparent',
            }"
            :cell-style="first_column_style"
          >
            <el-table-column type="index" min-width="30" width="100%">
              <template slot-scope="scope">
                <span style="color: rgb(38, 50, 129)">
                  {{ scope.$index + 1 + pageSize*(filter_nodes_current-1) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="ID" min-width="100">
              <template slot-scope="scope">
                <div style="display: flex">
                  <el-tooltip
                    v-ak-tooltip-auto-show
                    effect="light"
                    placement="top-end"
                    :content="scope.row.key"
                  >
                    <span
                      v-limits-of-authority="'NODE_DETAIL_READ'"
                      style="
                        cursor: pointer;
                        font-weight: bold;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: rgb(255, 195, 0);
                      "
                      @click="go_node_detial(scope.row.key)"
                      >{{ scope.row.key }}</span
                    >
                  </el-tooltip>
                  <div style="display: inline-block">
                    <div
                      v-limits-of-authority="'FILE_READ'"
                      style="display: inline-block"
                    >
                      <i
                        style="
                          font-size: 12px;
                          margin-left: 16px;
                          cursor: pointer;
                          color: rgb(48, 100, 143);
                        "
                        class="iconfont icon-node_link"
                        @click="nodeLink(scope.row)"
                      ></i>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="fileType"
              :label="$t('baseline.nodeTable.type')"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <span>{{
                  fileType[row.fileTypeId] ? fileType[row.fileTypeId].name : ""
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="topic"
              :label="$t('baseline.nodeTable.name')"
              min-width="180"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <span style="cursor: pointer" @click="show_drawer(row)">{{
                  row.topic
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('baseline.nodeTable.status')"
              min-width="90"
            >
              <template slot-scope="scope">
                <status-tag
                  v-model="status[scope.row.status].name"
                  :style_catogry="'plain'"
                  :status_type="status[scope.row.status].statusCategory"
                  :size="'small'"
                >
                </status-tag>
              </template>
            </el-table-column>
            <!-- 节点类型 -->
            <!-- <el-table-column
              prop="fileType"
              label="节点类型"
              min-width="120">
            </el-table-column> -->

            <el-table-column
              :label="$t('baseline.nodeTable.operation')"
              min-width="80"
            >
              <template slot-scope="scope">
                <div class="filter_tag">
                  <div
                    v-for="(item, index) in scope.row.label"
                    :key="index"
                    :class="item"
                  >
                    {{
                      item == "ADD_NODE"
                        ? $t("baseline.nodeTable.operationList.new")
                        : $t("baseline.nodeTable.operationList.change")
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="baselineNodeCreatedBy"
              :label="$t('baseline.nodeTable.operator')"
              min-width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="baselineNodeCreatedAt"
              :label="$t('baseline.nodeTable.operationTime')"
              min-width="150"
              :formatter="formatCreateTime"
            >
            </el-table-column>
            <el-table-column label="" width="40" align="right">
              <template slot-scope="scope">
                <i
                  class="iconfont icon-change_log"
                  style="cursor: pointer"
                  @click="openChangeLog(scope.row)"
                >
                </i>
              </template>
            </el-table-column>
            <el-table-column v-if="is_edit" label="" width="40" align="right">
              <template slot-scope="scope">
                <i
                  v-limits-of-authority="'NODE_EDIT'"
                  class="iconfont icon-baseline-delete"
                  style="cursor: pointer; color: #f56c6c"
                  @click="handleDelete(scope.$index, scope.row.key)"
                >
                </i>
              </template> </el-table-column
          ></el-table>
        </div>
      </collapse>
    </div>
    
    <div class="scroll_body">
      <collapse class="table_collapse">
        <template slot="title">
            {{ $t('baseline.nodeTable.block2') }}
        </template> 
        <template slot="pagination">
          <el-pagination
              :page-size="pageSize"
              :pager-count="pageCount"
              :current-page.sync="delete_nodes_current"
              class="header-pagination"
              :background="false"
              small
              layout="prev, pager, next"
              :total="delete_nodes.length">
            </el-pagination>
        </template>
        <div class="baseline-nodes">
          <el-table
            v-loading="loading"
            :data="current_delete_nodes"
            :show-header="false"
            :header-cell-style="set_title_style"
            :cell-style="first_column_style"
            style="background-color: rgba(229, 229, 229, 1)"
          >
            <el-table-column type="index" min-width="30"  width="100%">
              <template slot-scope="scope">
                <span style="color: rgb(38, 50, 129)">
                  {{ scope.$index + 1 + pageSize*(delete_nodes_current-1)}}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="ID" min-width="100">
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <el-tooltip
                    v-ak-tooltip-auto-show
                    effect="light"
                    placement="top-end"
                    :content="scope.row.key"
                    >key
                    <span
                      v-limits-of-authority="'NODE_DETAIL_READ'"
                      style="
                        cursor: pointer;
                        font-weight: bold;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: rgb(255, 195, 0);
                      "
                      @click="go_node_detial(scope.row.key)"
                      >{{ scope.row.key }}</span
                    >
                  </el-tooltip>
                  <div style="display: inline-block">
                    <div
                      v-limits-of-authority="'FILE_READ'"
                      style="display: inline-block"
                    >
                      <i
                        style="
                          font-size: 12px;
                          margin-left: 16px;
                          cursor: pointer;
                          color: rgb(48, 100, 143);
                        "
                        class="iconfont icon-node_link"
                        @click="nodeLink(scope.row)"
                      ></i>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="fileType"
              :label="$t('baseline.nodeTable.type')"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <span>{{
                  fileType[row.fileTypeId] ? fileType[row.fileTypeId].name : ""
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="topic"
              :label="$t('baseline.nodeTable.name')"
              min-width="180"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <span style="cursor: pointer" @click="show_drawer(row)">{{
                  row.topic
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('baseline.nodeTable.status')"
              min-width="90"
            >
              <template slot-scope="scope">
                <status-tag
                  v-model="status[scope.row.status].name"
                  :style_catogry="'plain'"
                  :status_type="status[scope.row.status].statusCategory"
                  :size="'small'"
                >
                </status-tag>
              </template>
            </el-table-column>
            <!-- 节点类型 -->
            <!-- <el-table-column
              prop="fileType"
              label="节点类型"
              min-width="120">
            </el-table-column> -->
            <el-table-column
              :label="$t('baseline.nodeTable.operation')"
              min-width="80"
            >
            </el-table-column>
            <el-table-column
              prop="lastChangedBy"
              :label="$t('baseline.nodeTable.operator')"
              min-width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="lastChangedAt"
              :label="$t('baseline.nodeTable.operationTime')"
              min-width="150"
              :formatter="formatCreateTime"
            >
            </el-table-column>
            <el-table-column label="" width="40" align="right">
              <template slot-scope="scope">
                <i
                  class="iconfont icon-change_log"
                  style="cursor: pointer"
                  @click="openChangeLog(scope.row)"
                >
                </i>
              </template>
            </el-table-column>
            <el-table-column v-if="is_edit" label="" width="40" align="right">
              <template slot-scope="scope">
                <i
                  v-limits-of-authority="'NODE_EDIT'"
                  class="iconfont icon-reverse1"
                  style="cursor: pointer"
                  @click="reverseDelete(scope.row.key)"
                >
                </i>
              </template> </el-table-column
          ></el-table>
        </div>
      </collapse>
    </div>
    <!--  change log弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="changelog_dialog"
      width="40%"
      top="25vh"
      :title="changelog.title"
      custom-class="changelog_dialog"
    >
      <el-table
        :data="changelog.data"
        height="250"
        style="margin-bottom: 24px"
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="{
          color: '#636363',
          'font-weight': '700',
        }"
      >
        <el-table-column
          property="createdAt"
          :label="$t('baseline.history.date')"
          align="left"
          min-width="140"
          :formatter="formatCreateTime"
        ></el-table-column>
        <el-table-column
          property="createdByName"
          :label="$t('baseline.history.user')"
          min-width="120"
          align="left"
        ></el-table-column>
        <el-table-column
          :label="$t('baseline.history.activity')"
          align="left"
          min-width="150"
        >
          <template slot-scope="scope">
            <!-- style="color: rgb(64, 158, 255); cursor: pointer"
              @click="to_his(scope.row)" -->
            <span v-if="scope.row.changeType === 'CONTENT_MODIFY'">{{
              get_change_content(scope.row.changeType)
            }}</span>
            <span v-else>{{ get_change_content(scope.row.changeType) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <NodeDrawer
      :drawer.sync="drawer"
      :current_node="current_node"
      :file_icon_name="file_icon_name"
      :drawer_loading="drawer_loading"
      :file_icon="file_icon"
      :members-show="membersShow"
      :members="members"
      :show-test-plan="showTestPlan"
      @close="before_close_drawer"
      @loading-finished="drawer_loading = false"
      @update_description_content="update_description_content"
      @update_current_node="update_current_node"
    />
   
  </div>
</template>

<script>
import { get_node_use_node_key } from "@/network/node/index.js";
import Collapse from "./Collapse.vue";
import BaselineAddnode from "./BaselineAddnode";
import StatusTag from "@/components/statusTag";
import { formatTime } from "@/utils/common";
import NodeDrawer from "@/components/nodeDrawer";
import { inProgressBaselines } from "@/network/merge/index";
import { getFile } from "@/network/home/index.js";
import vmson from "@/utils/vmson";
import {
  change_lock,
  get_nodes,
  delete_nodes,
  recover_node,
  get_file,
  get_changelog,
} from "@/network/baseline/index.js";
import { mapGetters } from "vuex";
export default {
  name: "BaselineBeforeStartTable",
  components: {
    Collapse,
    BaselineAddnode,
    NodeDrawer,
    StatusTag,
  },
  props: {
    is_edit: {
      type: Boolean,
      default: false,
    },
    is_starting: {
      type: Boolean,
      default: true,
    },
    is_lock: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 当前项目成员
      drawer: false,
      current_node: {},
      drawer_loading: false,
      file_icon_name: "",
      file_icon: "",
      // 节点列表
      nodes_table: [],
      filter_nodes_table: [],
      current_filter_nodes_table: [],
      delete_nodes: [],
      current_delete_nodes: [],
      filters: [
        {
          text: this.$t("baseline.nodeTable.operationList.new"),
          value: "ADD_NODE",
        },
        {
          text: this.$t("baseline.nodeTable.operationList.change"),
          value: "CONTENT_MODIFY",
        },
      ],
      changelog_dialog: false,
      changelog: {
        title: "",
        data: null,
      },
      loading: true,
      typeFiltersList: [],
      // 删除节点袁术数据
      deleteNodeOrigin: [],
      // nodeTypeList:[],//类型列表
      // node_filters:[],//筛选列表
      pageSize: 8,
      pageCount: 5,
      filter_nodes_current: 1,
      delete_nodes_current: 1,
    };
  },
  computed: {
    ...mapGetters(["user_list", "status", "fileType", "project_user_list"]),
    baselineId() {
      return this.$store.getters.baselineId;
    },
    showTestPlan() {
      if (this.current_node && this.current_node.fileTypeId && this.fileType) {
        return this.fileType[this.current_node.fileTypeId]
          ? this.fileType[this.current_node.fileTypeId].testCase
          : false;
      }
      return false;
    },
    members() {
      return Object.values(this.project_user_list);
    },
    membersShow() {
      return this.members.length ? true : false;
    },
  },
  watch:{
    filter_nodes_current:{
      handler(newv, oldv){
        this.current_filter_nodes_table = this.filter_nodes_table.slice((newv-1)*this.pageSize,newv*this.pageSize)
      },
    },
    filter_nodes_table:{
      handler(newv, oldv){
        this.current_filter_nodes_table = this.filter_nodes_table.slice((this.filter_nodes_current-1)*this.pageSize,this.filter_nodes_current*this.pageSize)
      },
      immediate:true
    },
    delete_nodes_current:{
      handler(newv, oldv){
        this.current_delete_nodes = this.delete_nodes.slice((newv-1)*this.pageSize,newv*this.pageSize)
      },
    },
    delete_nodes:{
      handler(newv, oldv){
        this.current_delete_nodes = this.delete_nodes.slice((this.delete_nodes_current-1)*this.pageSize,this.delete_nodes_current*this.pageSize)
      },
      immediate:true
    },
  },
  mounted() {
    this.get_table_data();
  },

  methods: {
    get_change_content(type) {
      switch (type) {
        case "ADD_NODE":
          return this.$t("baseline.info.addNode");
        case "DELETE_NODE":
          return this.$t("baseline.info.delNode");
        case "CONTENT_MODIFY":
          return this.$t("baseline.info.modNode");
        case "RECOVER_NODE":
          return this.$t("baseline.info.recNode");
      }
    },
    reload() {
      this.$emit("reload");
    },
    get_table_data() {
      this.typeFiltersList = [];
      const map = {};
      get_nodes(this.get_pid(), this.baselineId).then((res) => {
        this.nodes_table = res.filter((item) => {
          item.baselineNodeCreatedBy = this.matchUserNickname(
            item.baselineNodeCreatedBy,
            item.baselineNodeCreatedBy
          );
          return !item.isDel;
        });
        this.filter_nodes_table = this.nodes_table;
        // console.log('原始列表',this.nodes_table);
        this.deleteNodeOrigin = res.filter((item) => {
          item.lastChangedBy = this.matchUserNickname(
            item.lastChangedBy,
            item.lastChangedBy
          );
          return item.isDel;
        });
        this.delete_nodes = this.deleteNodeOrigin;
        vmson.$emit("nodes_table", this.nodes_table); //基线内容传递给基线存档组件
        this.loading = false;
        res.forEach((item) => {
          const name = this.fileType[item.fileTypeId]
            ? this.fileType[item.fileTypeId].name
            : "";
          if (!map[name]) {
            const obj = {
              text: name,
              value: name,
            };
            this.typeFiltersList.push(obj);
            map[name] = true;
          }
        });
        // this.add_nodesType()//节点类型添加
      });
    },
    set_title_style({ row, column, rowIndex, columnIndex }) {
      let style =
        " color: rgba(84, 85, 86, 1);font-size: 14px;fontWeight: bold;padding:8px 0;";
      if (columnIndex == 0) style += "padding-left:10px;";
      return style;
    },
    first_column_style({ columnIndex }) {
      let style = "padding:8px 0;";
      if (columnIndex == 0) {
        style += "color:rgba(255, 195, 0, 1);padding-left:10px;";
      } else {
        style += "color:#636363; font-weight: 700";
      }
      return style;
    },
    change_node_lock() {
      if (this.is_lock) {
        this.$confirm(
          this.$t("baseline.unlockNode.content"),
          this.$t("baseline.unlockNode.title"),
          {
            confirmButtonText: this.$t("btn.confirmBtn"),
            cancelButtonText: this.$t("btn.cancelBtn"),
            cancelButtonClass: "info",
            confirmButtonClass: "el-button--primary",
            type: "warning",
          }
        ).then(() => {
          change_lock(this.get_pid(), this.baselineId).then(() => {
            this.$message.success(this.$t("baseline.message.unlockSuccess"));
            this.reload();
          });
        });
      } else {
        this.$confirm(
          this.$t("baseline.lockNode.content"),
          this.$t("baseline.lockNode.title"),
          {
            confirmButtonText: this.$t("btn.confirmBtn"),
            cancelButtonText: this.$t("btn.cancelBtn"),
            cancelButtonClass: "el-button--primary",
            confirmButtonClass: "info",
            type: "warning",
          }
        ).then(() => {
          change_lock(this.get_pid(), this.baselineId).then(() => {
            this.$message.success(this.$t("baseline.message.lockSuccess"));
            this.reload();
          });
        });
      }
    },
    handleDelete(index, nodeKey) {
      this.nodes_table.splice(index, 1);
      delete_nodes(this.get_pid(), this.baselineId, nodeKey).then(() => {
        this.$message.success(this.$t("baseline.message.deleteSuccess"));
        this.get_table_data();
      });
    },
    nodeLink(row) {
      get_file(this.get_pid(), row.fileId).then((result) => {
        getFile(this.get_pid(), result.key).then((res) => {
          this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
          this.$store.commit("SET_FILE_ID", result.id);
          this.$store.commit("SET_FILE_KEY", result.key);
          this.$store.commit("SET_MINDMAP_NAME", result.name);
          this.$store.commit("SET_OPEN_STATE", "exist");
          this.$store.commit("SET_FILETYPE_ID", result.fileTypeId);
          //跳转到思维导图
          const { href } = this.$router.resolve({
            path: `/${this.get_pid()}/myMind/${result.key}`,
            query: {
              node_id: row.id, // 节点id
            },
          });
          window.open(href, "_blank");
        });
      });
    },
    filterHandler(filterObj) {
      if (filterObj.filterTag) {
        if (filterObj.filterTag.length > 0) {
          // let filter = filterObj.filterTag[0];
          for (let filter of filterObj.filterTag) {
            this.filter_nodes_table = this.nodes_table.filter((item) =>
              item.label.includes(filter)
            );
          }
        } else {
          this.filter_nodes_table = this.nodes_table;
        }
      } else if (filterObj.filterFileType) {
        if (filterObj.filterFileType.length > 0) {
          this.filter_nodes_table = this.nodes_table.filter((item) => {
            return (
              filterObj.filterFileType.indexOf(
                this.fileType[item.fileTypeId].name
              ) !== -1
            );
          });
          this.delete_nodes = this.deleteNodeOrigin.filter((item) => {
            return (
              filterObj.filterFileType.indexOf(
                this.fileType[item.fileTypeId].name
              ) !== -1
            );
          });
        } else {
          this.filter_nodes_table = this.nodes_table;
          this.delete_nodes = this.deleteNodeOrigin;
        }
      }
    },
    formatCreateTime(row, column, cellValue, index) {
      return formatTime(cellValue);
    },
    go_node_detial(nodeKey) {
      const { href } = this.$router.resolve({
        name: "node_detail",
        params: {
          projectId: this.get_pid(),
          nodeKey,
        },
      });
      window.open(href, "_blank");
    },
    reverseDelete(nodeKey) {
      recover_node(this.get_pid(), this.baselineId, nodeKey).then(() => {
        this.$message.success(this.$t("baseline.message.revertSuccess"));
        this.get_table_data();
      });
    },
    openChangeLog(row) {
      get_changelog(this.get_pid(), this.baselineId, row.key).then((res) => {
        this.changelog_dialog = true;
        this.changelog.title = row.key;
        this.changelog.data = res;
        this.changelog.data.forEach((item) => {
          item.createdByName = this.matchUserNickname(item.createdBy, "");
        });
      });
    },
    //自定义表头
    renderHeader(h) {
      let _this = this;
      let icon = this.is_lock ? "el-icon-lock" : "el-icon-unlock";
      if (this.is_starting) {
        return h("span", {}, [
          h("span", {}, "Key"),
          h(
            "i",
            {
              class: icon,
              directives: [
                {
                  name: "limits-of-authority",
                  value: "DATUM_LINE_CANCEL_NODE_LOCK",
                },
              ],
              style:
                "margin-left: 5px;cursor: pointer;color: rgba(48, 100, 143, 1);",
              on: {
                click: _this.change_node_lock,
              },
            },
            ""
          ),
        ]);
      } else {
        return h("span", {}, "Key");
      }
    },
    before_close_drawer() {
      this.get_table_data();
      this.drawer = false;
    },
    show_drawer(node) {
      const jobAuthorities = this.$store.getters.jobAuthorities;
      if (jobAuthorities.indexOf("NODE_DETAIL_READ") === -1) {
        this.$message({
          message: this.$t("board.message.error"),
          type: "warning",
        });
        return;
      }
      this.drawer_loading = true;
      this.$nextTick(() => {
        get_node_use_node_key(this.get_pid(), node.key).then((res) => {
          this.current_node = res;
          this.drawer = true;
        });
      });
    },
    update_description_content(description) {
      this.current_node.description = description;
    },
    update_current_node(e) {
      this.current_node = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.baseline_started_table {
  display: flex;
  flex-direction: column;
  .hide_body {
    border-radius: 8px;
    margin-top: 20px;
    flex: none;
    ::v-deep .el-table__column-filter-trigger i {
      margin-left: 5px;
      font-size: 20px;
    }
    ::v-deep .el-table__body-wrapper {
      position: absolute;
    }
  }
  .table_collapse {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding-top: 20px;
    ::v-deep .collapse-main {
      margin-left: 0;
    }
  }
  .baseline-nodes {
    border-radius: 8px;
    background-color: #fff;
    ::v-deep .el-table::before {
      height: 0;
    }
    ::v-deep .el-table {
      border-radius: 8px;
      svg {
        cursor: pointer;
      }
      tbody tr:hover > td {
        background: none;
      }
      .ADD_NODE,
      .CONTENT_MODIFY {
        position: relative;
        padding: 0 18px;
        &::before {
          content: " ";
          position: absolute;
          left: 0px;
          top: 18%;
          width: 12px;
          height: 12px;
          background-color: rgba(67, 207, 124, 1);
          border-radius: 6px;
        }
      }
      .CONTENT_MODIFY {
        &::before {
          background-color: rgba(42, 130, 228, 1);
        }
      }
    }
    ::v-deep .el-table__row {
      td:nth-child(2) {
        > div {
          font-weight: bold;
        }
      }
    }
  }
  .add_nodes {
    text-align: start;
    margin-top: 18px;
    .title {
      font-size: 14px;
      color: rgba(144, 147, 153, 1);
      margin-right: 5px;
    }
    ::v-deep.el-input {
      width: 318px;
      margin-right: 12px;
    }
  }
  .scroll_body {
    flex: 1;
    overflow: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  ::v-deep .changelog_dialog {
    .el-dialog__header {
      .el-dialog__title {
        color: rgba(255, 195, 0, 1);
      }
    }
    .el-dialog__body {
      ::-webkit-scrollbar {
        width: 6px;
        height: 8px;
        background-color: #ebeef5;
      }
      ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        background: rgba(255, 255, 255, 1);
      }
    }
  }
}
::v-deep .el-pagination {
  display: inline-block;
  .btn-prev{
    background: transparent !important;
  }
  .btn-next{
    background: transparent !important;
  }
  li{
    background: transparent !important;
  }
}
</style>
