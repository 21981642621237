<template>
  <div
    class="baseline_start"
    style="height: 100%; display: flex; flex-direction: column"
  >
    <!-- 编辑状态 -->
    <div v-show="is_edit" class="header">
      <div class="header__baseline_name">
        <span
          >{{ select_data.name }} &nbsp; ({{
            $t("canvas.testPlan.status.started")
          }})</span
        >
      </div>
      <div class="header__btns">
        <div class="save_tip">
          <i class="iconfont icon-refresh" :class="{ svg_animation: saving }">
          </i>
          {{ $t("baseline.topBar.autoSave") }}
        </div>
        <baseline-deletebtn
          @edit="edit"
          @change_baseline="change_baseline"
        ></baseline-deletebtn>
        <el-button type="info" size="small" @click="exit_edit"
          ><i class="iconfont icon-exit" style="margin-right: 5px"></i
          >{{ $t("btn.exitEditBtn") }}</el-button
        >
      </div>
    </div>
    <!-- 非编辑 -->
    <div v-show="!is_edit" class="header">
      <div class="header__baseline-select">
        <baseline-select
          v-if="show_baseline_select"
          :isDataLoaded="isDataLoaded"
          v-model="select_data"
          @change_baseline="change_baseline"
        ></baseline-select>
        <div>
          <div
            v-limits-of-authority="'DATUM_LINE_START_END'"
            class="end"
            @click="to_end"
          >
            <!-- <i
              class="iconfont icon-pause"
              style="color: rgb(48, 100, 143); font-size: 25px"
            ></i> -->
            <button class="endBtn">
              <i
                class="iconfont icon-pause"
                style="color: rgb(255, 255, 255); font-size: 21px"
              ></i>
              <span class="endText">{{ $t("btn.endBtn") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="header__btns">
        <!-- 查看存档按钮 -->
        <baseline-archive-viewbtn> </baseline-archive-viewbtn>
        <!-- 基线存档按钮 -->
        <baseline-archivebtn> </baseline-archivebtn>
        <div>
          <baseline-copybtn
            v-limits-of-authority="'DATUM_LINE_CREATE_EDIT_COPY'"
          ></baseline-copybtn>
        </div>
        <div>
          <el-button
            type="primary"
            :disabled="
              limits_of_authority_disable('DATUM_LINE_CREATE_EDIT_COPY')
            "
            v-limits-of-authority="'DATUM_LINE_CREATE_EDIT_COPY'"
            size="small"
            @click="edit(true)"
            ><i class="iconfont icon-branch_edit" style="margin-right: 5px"></i
            >{{ $t("btn.editBtn") }}</el-button
          >
        </div>
      </div>
    </div>
    <div @mouseenter="summaryOnmouserEnter" @mouseleave="summaryOnmouserLeave">
      <el-collapse
        v-model="activeNames"
        class="scroll_wrap"
        @change="handleChange"
      >
        <el-collapse-item class="collapse_item" name="1">
          <div slot="title" class="collapse-title">
            {{ $t(`nodeDetail.basicInfo.title`) }}
          </div>
          <div class="baseline-info">
            <div class="create-time-and-person">
              <el-row style="width: 100%">
                <el-col :span="8">
                  <div class="create-time">
                    <div>
                      <span class="title"
                        >{{ $t("baseline.basicInfo.startTime") }}:
                      </span>
                      {{ baseline_info.startDateTime }}
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="started_name">
                    <span class="title"
                      >{{ $t("baseline.basicInfo.startPeople") }}:
                    </span>
                    {{ baseline_info.startedByName }}
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-if="is_edit" class="baseline-description">
              <span class="title"
                >{{ $t("baseline.basicInfo.baselineDesc") }}: </span
              ><el-input
                v-model="baseline_desc"
                type="textarea"
                :autosize="{ maxRows: 4 }"
                @blur="change_desc"
              ></el-input>
            </div>
            <div v-else class="baseline-description">
              <span class="title_show"
                >{{ $t("baseline.basicInfo.baselineDesc") }}:
              </span>
              <span class="plan-text"
                ><el-scrollbar style="height: 100%">
                  <span v-html="newDescription"></span></el-scrollbar
              ></span>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 节点表格 -->
    <baseline-started-table
      v-if="show_table"
      :is_edit="is_edit"
      :is_lock="baseline_info.lock"
      @reload="reload"
    ></baseline-started-table>
  </div>
</template>

<script>
import { limits_of_authority_disable } from "@/utils/limits";
import BaselineSelect from "@/components/release/components/BaselineSelect";
import BaselineCopybtn from "./BaselineCopybtn";
import BaselineStartedTable from "./BaselineStartedTable";
import BaselineDeletebtn from "./BaselineDeletebtn";
import BaselineArchiveViewbtn from "./BaselineArchiveViewbtn.vue";
import BaselineArchivebtn from "./BaselineArchivebtn.vue";
import {
  get_data,
  finish_baseline,
  change_baseinfo,
} from "@/network/baseline/index";

export default {
  components: {
    BaselineSelect,
    BaselineCopybtn,
    BaselineStartedTable,
    BaselineDeletebtn,
    BaselineArchivebtn,
    BaselineArchiveViewbtn,
  },
  props: {
    dataLoaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseline_info: {},
      baseline_desc: "",
      baseline_options: [],
      select_data: {
        baselineId: "",
        name: "",
        status: "",
      },
      show_table: true,
      show_baseline_select: true,
      saving: false,
      newDescription: "",
      activeNames: [],
      isDataLoaded: false,
    };
  },
  computed: {
    baselineId() {
      return this.$store.getters.baselineId;
    },
    is_edit: {
      get() {
        return this.$store.getters.is_edit;
      },
      set(val) {
        this.$store.commit("SET_BASELINE_EDIT", val);
      },
    },
  },
  watch: {
    dataLoaded: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.isDataLoaded = true;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.get_baseline_data();
  },

  methods: {
    limits_of_authority_disable,
    summaryOnmouserEnter() {
      this.timeOut = setTimeout(() => {
        if (this.activeNames.indexOf("1") == -1 && !this.is_edit) {
          this.activeNames.push("1");
        }
      }, 500);
    },
    summaryOnmouserLeave() {
      clearTimeout(this.timeOut);
      if (this.activeNames.indexOf("1") !== -1 && !this.is_edit) {
        this.activeNames.splice(this.activeNames.indexOf("1"), 1);
      }
    },
    reload() {
      this.get_baseline_data();
      this.show_table = false;
      this.show_baseline_select = false;
      this.$nextTick(() => {
        this.show_table = true;
        this.show_baseline_select = true;
      });
    },
    get_baseline_data() {
      get_data(this.get_pid(), this.baselineId).then((res) => {
        this.baseline_info = res;
        this.baseline_desc = res.description;
        this.baseline_info.startedByName = this.matchUserNickname(
          this.baseline_info.startedBy,
          ""
        );
        Object.keys(this.select_data).forEach((item) => {
          this.select_data[item] = res[item];
        });
        this.change_description();
      });
    },
    edit(val) {
      this.is_edit = val;
      if (val) {
        this.activeNames = ["1"];
      }
    },
    to_end() {
      this.$confirm(
        this.$t("baseline.topBar.endBaseline.content"),
        this.$t("baseline.topBar.endBaseline.title"),
        {
          confirmButtonText: this.$t("btn.confirmBtn"),
          confirmButtonClass: "el-button--primary",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      ).then(() => {
        finish_baseline(this.get_pid(), this.baselineId).then(() => {
          this.$message.success(this.$t("baseline.message.endSuccess"));
          this.$store.commit("SET_BASELINE_STATUS", "COMPLETE");
        });
      });
    },
    change_baseline(baselineId, status) {
      this.is_edit = false;
      // 发送请求,重新渲染页面
      this.$store.commit("SET_BASELINE_ID", baselineId);
      this.$store.commit("SET_BASELINE_STATUS", status);
      this.reload();
      this.$router.push({
        name: "baseline",
        params: { cur_id: this.baselineId },
      });
    },
    exit_edit() {
      this.is_edit = false;
      this.activeNames = [];
      this.reload();
    },
    change_desc() {
      this.saving = true;

      this.change_description();

      change_baseinfo(this.get_pid(), this.baselineId, {
        description: this.baseline_desc,
      }).then(() => {
        this.saving = false;
      });
    },
    // 对描述进行换行替换处理
    change_description() {
      this.newDescription = this.baseline_desc.replace(/\n/g, "<br>");
    },
    handleChange() {},
  },
};
</script>

<style lang="scss" scoped>
.collapse_item {
  margin-bottom: 10px;
  margin-top: 17px;
  border-radius: 8px;
}
.collapse-title {
  display: flex;
  align-items: center;

  height: 27px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 500;
  left: 40px;
  order: 1;
}
.el-collapse-item__header {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  order: -1;
  background: rgba(229, 229, 229, 1);
  border-radius: 8px !important;
}
::v-deep .el-collapse-item__arrow {
  width: 20px;
  height: 20px;
  background: rgba(229, 229, 229, 1);
  margin-left: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-collapse-item__header {
  border-radius: 8px;
}

.baseline_start {
  @keyframes myrotate {
    100% {
      transform: rotate(360deg);
    }
  }
  // 顶部下拉框和按钮组
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header__baseline_name {
      font-size: 16px;
      color: rgba(112, 112, 112, 1);
    }
    &__btns {
      display: flex;
      align-items: center;
      > * {
        margin-left: 10px;
      }
      .save_tip {
        font-size: 12px;
        color: rgba(166, 166, 166, 1);
        margin-right: 24px;
        .svg_animation {
          animation: myrotate 1s linear infinite;
        }
      }
      ::v-deep.el-button {
        font-size: 16px;
        // padding: 6px 10px;
      }
    }
    &__baseline-select {
      display: flex;
      align-items: center;
      ::v-deep.el-input__inner {
        background-color: transparent;
      }
      .end {
        margin: 0 12px;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }
  // 版本信息区域
  .baseline-info {
    padding: 17px 23px 0px;
    border-radius: 8px;
    background-color: #fff;
    font-size: 14px;
    line-height: 20px;
    color: rgba(128, 128, 128, 1);
    .create-time-and-person {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      text-align: left;
    }
    .baseline-description {
      text-align: start;
      display: flex;
      word-break: break-all;
      .title {
        flex: 7%;
      }
      .title_show {
        width: 100px; /*no*/
      }
      .plan-text {
        max-height: 80px;
        overflow-y: auto;
        flex: 1;
        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }
      }
    }
    .title,
    .title_show {
      font-weight: 700;
    }
  }
  .add_nodes {
    text-align: start;
    margin-top: 18px;
    .title {
      font-size: 14px;
      color: rgba(144, 147, 153, 1);
      margin-right: 5px;
    }
    ::v-deep.el-input {
      width: 318px;
      margin-right: 12px;
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  margin-bottom: 0 !important;
}

.endBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 32px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(40, 78, 163, 1);
  border-style: none;
  cursor: pointer;
  .endText {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    margin-left: 5px;
    letter-spacing: 1px;
    line-height: 5px;
  }
}
</style>
