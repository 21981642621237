<template>
  <div>
    <el-button
      type="info"
      :disabled="limits_of_authority_disable('DATUM_LINE_DELETE')"
      size="small"
      @click="confirm_delete"
      ><i class="iconfont icon-delete" style="margin-right: 5px"></i
      >{{ $t("btn.deleteBtn") }}</el-button
    >
  </div>
</template>

<script>
import {
  delete_baseline,
  get_baseline_list,
} from "@/network/baseline/index.js";
import { limits_of_authority_disable } from "@/utils/limits";
import { mapActions } from "vuex";
export default {
  inject: ["reload"],
  data() {
    return {
      baseline_list: [],
    };
  },
  computed: {
    baselineId() {
      return this.$store.getters.baselineId;
    },
  },
  mounted() {},

  methods: {
    ...mapActions(["setBaseLine"]),
    limits_of_authority_disable,
    // 删除当前基线
    confirm_delete() {
      this.$confirm(
        this.$t("baseline.topBar.deleteBaselineForm.info"),
        this.$t("baseline.topBar.deleteBaselineForm.title"),
        {
          confirmButtonText: this.$t("btn.deleteBtn"),
          confirmButtonClass: "delete_button_class",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      ).then(() => {
        this.baseline_list = [];
        delete_baseline(this.get_pid(), this.baselineId).then(() => {
          this.$message.success(this.$t("baseline.message.deleteSuccess"));
          this.$store.commit("SET_BASELINE_EDIT", false);
          this.setBaseLine(this.get_pid());
          // to do 渲染列表中第一个基线
          get_baseline_list(this.get_pid()).then((res) => {
            const obj = {};
            const list = [
              ...(res.NOT_STARTED || []),
              ...(res.ALREADY_STARTED || []),
              ...(res.COMPLETE || []),
            ];
            list.forEach((item) => {
              obj[item.baselineId] = item;
            });
            this.$store.commit("setBaseLine", obj);
            let property = ["ALREADY_STARTED", "NOT_STARTED", "COMPLETE"];
            property.forEach((type) => {
              if (res[type]) {
                this.baseline_list = [...this.baseline_list, ...res[type]];
              }
            });
            let baseline_arr = this.baseline_list.filter(
              (item) => item.baselineId != this.baselineId
            );
            if (baseline_arr.length > 0) {
              let { baselineId, status } = baseline_arr[0];
              this.$emit("change_baseline", baselineId, status);
            } else {
              this.reload();
            }
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
