<template>
  <div class="node_select">
    <div class="add_nodes">
      <!-- <span class="title">{{ $t("baseline.addNode.title") }}</span> -->
      <el-select
        v-model="new_nodes"
        multiple
        size="small"
        filterable
        remote
        reserve-keyword
        collapse-tags
        :placeholder="$t('baseline.addNode.placeholder')"
        :loading-text="$t('loading.loading2')"
        :remote-method="get_add_nodes_options"
        :loading="nodes_option_loading"
        :popper-append-to-body="false"
        @visible-change="clearData"
        popper-class="select-icon-height-auto-class"
      >
        <el-option
          v-for="item in options"
          :key="item.nodeKey"
          :label="`${item.nodeKey} ${item.topic}`"
          :value="item.nodeKey"
          style="text-align: left;"
        >
          <select-icon :item="item"> </select-icon>
        </el-option>
      </el-select>
      <span>
        <el-button
          v-limits-of-authority="'NODE_EDIT'"
          :disabled="limits_of_authority_disable('NODE_EDIT')"
          type="primary"
          size="mini"
          @click="submit"
          >{{ $t("btn.addBtn") }}</el-button
        >
      </span>
    </div>
  </div>
</template>

<script>
import { add_nodes, get_nodes } from "@/network/baseline/index.js";
import { get_relation_node } from "@/network/node/index.js";
import selectIcon from "@/components/selectIcon";
import { limits_of_authority_disable } from "@/utils/limits";
export default {
  props: {
    appen_to_body: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    selectIcon,
  },
  data() {
    return {
      nodes: [],
      nodes_option_loading: false,
      options: [],
      new_nodes: [],
    };
  },
  computed: {
    baselineId() {
      return this.$store.getters.baselineId;
    },
  },
  mounted() {
    this.get_table_data();
  },

  methods: {
    limits_of_authority_disable,
    get_table_data() {
      get_nodes(this.get_pid(), this.baselineId).then((res) => {
        this.nodes = res.map((item) => item.nodeKey);
      });
    },
    // 获得模糊搜索的节点列表
    get_add_nodes_options(query) {
      if (query !== "") {
        this.nodes_option_loading = true;
        get_relation_node(this.get_pid(), query).then((res) => {
          if (this.nodes.length > 0) {
            this.options = res.filter((item) => {
              return !this.nodes.includes(item.nodeKey);
            });
          } else {
            this.options = res;
          }
          this.nodes_option_loading = false;
        });
      } else {
        this.options = [];
      }
    },
    clearData() {
      if (this.new_nodes.length == 0) this.options = [];
    },
    submit() {
      add_nodes(this.get_pid(), this.baselineId, this.new_nodes).then(() => {
        this.$message.success(this.$t("baseline.message.addSuccess"));
        this.new_nodes = [];
        // todo 重新渲染列表
        this.$emit("reload");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
.add_nodes {
  text-align: end;
  margin-top: 18px;
  ::v-deep .el-select {
    .el-tag:first-child {
      display: flex;
      align-items: center;
      .el-select__tags-text {
        display: inline-block;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
  }
  .title {
    font-size: 14px;
    color: rgba(144, 147, 153, 1);
    margin-right: 5px;
  }
  ::v-deep .el-select-dropdown {
    // 解决下拉框错位
    position: absolute !important;
    // top: -277px !important;
    left: 0 !important;
    max-width: 400px;
    .popper__arrow {
      display: none;
    }
  }
  ::v-deep .el-input {
    width: 318px;
    margin-right: 12px;
  }
}
</style>
