<template>
  <div>
    <el-button
      type="info"
      :disabled="limits_of_authority_disable('DATUM_LINE_CREATE_EDIT_COPY')"
      size="small"
      @click="copy_baseline_dialog = true"
      ><i class="iconfont icon-copy" style="margin-right: 5px"></i>
      {{ $t("btn.copyBtn") }}</el-button
    >
    <!--  复制基线弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="copy_baseline_dialog"
      width="25%"
      top="25vh"
      :title="$t('baseline.topBar.pasteBaselineForm.title')"
      custom-class="copy_baseline"
    >
      <el-form
        ref="copyForm"
        label-width="120px"
        :rules="rules"
        :model="copy_form"
        label-position="left"
      >
        <el-form-item prop="copy_name">
          <template slot="label">
            <span>{{ $t("baseline.topBar.pasteBaselineForm.name") }}</span>
          </template>
          <el-input v-model="copy_form.copy_name"> </el-input>
        </el-form-item>

        <el-form-item prop="copy_desc">
          <template slot="label">
            <span>{{ $t("baseline.topBar.pasteBaselineForm.desc") }}</span>
          </template>
          <el-input v-model="copy_form.copy_desc" type="textarea"> </el-input>
        </el-form-item>
      </el-form>
      <div class="tip">{{ $t("baseline.topBar.pasteBaselineForm.note") }}</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="copy_baseline_dialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          class="button-confirm"
          @click="copy_baseline"
          >{{ $t("btn.newBtn") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { copy_baseline } from "@/network/baseline/index.js";
import { limits_of_authority_disable } from "@/utils/limits";
import { mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      //复制基线弹窗
      copy_baseline_dialog: false,
      copy_form: {
        copy_name: "",
        copy_desc: "",
      },
      rules: {
        copy_name: [
          {
            required: true,
            message: this.$t("addEditCopy.name1"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {},

  methods: {
    ...mapActions(["setBaseLine"]),
    limits_of_authority_disable,
    copy_baseline() {
      this.$refs["copyForm"].validate((valid) => {
        if (valid) {
          let d = {
            description: this.copy_form.copy_desc,
            name: this.copy_form.copy_name,
          };
          copy_baseline(this.get_pid(), this.$store.getters.baselineId, d).then(
            (res) => {
              this.setBaseLine(this.get_pid());
              this.$store.commit("SET_BASELINE_ID", res);
              this.$store.commit("SET_BASELINE_STATUS", "NOT_STARTED");
              this.$router.go(0);
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .copy_baseline {
  text-align: left;
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .tip {
    text-align: left;
    font-size: 12px;
    color: rgba(166, 166, 166, 1);
  }
}
</style>
