<template>
  <div v-loading="loading" class="baseline_page">
    <release-default :dataLoaded="dataLoaded" v-if="!has_baseline"></release-default>
    <div v-else style="height: 100%">
      <baseline-before-start :dataLoaded="dataLoaded"
        v-if="baseline_status == 'NOT_STARTED'"
      ></baseline-before-start>
      <baseline-started :dataLoaded="dataLoaded"
        v-else-if="baseline_status == 'ALREADY_STARTED'"
      ></baseline-started>
      <baseline-end :dataLoaded="dataLoaded" v-else-if="baseline_status == 'COMPLETE'"> </baseline-end>
    </div>
  </div>
</template>

<script>
import BaselineBeforeStart from "./components/BaselineBeforeStart.vue";
import BaselineStarted from "./components/BaselineStarted";
import BaselineEnd from "./components/BaselineEnd";
import ReleaseDefault from "@/components/release";
import { get_baseline_list } from "@/network/baseline/index.js";
export default {
  components: {
    BaselineBeforeStart,
    BaselineStarted,
    BaselineEnd,
    ReleaseDefault,
  },
  data() {
    return {
      has_baseline: false,
      loading: true,
      dataLoaded: false
    };
  },
  computed: {
    baseline_status: {
      get() {
        return this.$store.getters.baseline_status;
      },
      set(val) {
        this.$store.commit("SET_BASELINE_STATUS", val);
      },
    },
    baselineId: {
      get() {
        return this.$store.getters.baselineId;
        // return this.$route.params.cur_id;
      },
      set(val) {
        this.$store.commit("SET_BASELINE_ID", val);
      },
    },
  },
  mounted() {
    this.get_baseline_options();
  },
  methods: {
    get_baseline_options() {
      // 处理第一次进入基线
      get_baseline_list(this.get_pid()).then((res) => {
        const obj = {};
        const list = [
          ...(res.NOT_STARTED || []),
          ...(res.ALREADY_STARTED || []),
          ...(res.COMPLETE || []),
        ];
        list.forEach((item) => {
          obj[item.baselineId] = item;
        });
        this.$store.commit("setBaseLine", obj);
        if (JSON.stringify(res) != "{}") {
          this.has_baseline = true;
          this.dataLoaded = true;
          //第一次进入或者从aside进入
          if (!this.baselineId || this.$route.params.cur_id == "enterOwn") {
            if (res["ALREADY_STARTED"]) {
              this.baselineId = res["ALREADY_STARTED"][0].baselineId;
              this.baseline_status = "ALREADY_STARTED";
            } else if (res["NOT_STARTED"]) {
              this.baselineId = res["NOT_STARTED"][0].baselineId;
              this.baseline_status = "NOT_STARTED";
            } else {
              this.baselineId = res["COMPLETE"][0].baselineId;
              this.baseline_status = "COMPLETE";
            }
          } else {
            //直接从地址栏带着id进入
            this.baselineId = this.$route.params.cur_id
              ? this.$route.params.cur_id
              : this.$route.params.baselineId;
          }
          this.$router.push({
            name: "baseline",
            params: { cur_id: this.baselineId },
          });
        } else {
          this.baselineId = "";
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.baseline_page {
  height: 100%;
}
</style>
